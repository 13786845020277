export const environment = {
  production  : false,
  env_name    : 'dev',
  BASE_URL    : '../hraccess_web/',
  IV_JCT      : '/hraccess-dev1',
  API_URL     : '../hraccess-registration-ws',
  LOGIN_URL   : 'https://hr-access-dev1.az.3pc.att.com/access_ws',
  former_emp  : 'https://webtest.stage.att.com/hraccess-dev1/hraccess-web-fe/#/former',
  active_emp  : 'https://webtest.stage.att.com/hraccess-dev1/hraccess-web-fe/#/active',
  HOME_URL    : 'https://hr-access-dev.att.com/hraccess',
  Global_Logon: `https://webtest.stage.att.com/empsvcs/hrpinmgt/pagLogin/?retURL=${window.location.href}&sysName=MOTSID29788`,
};
