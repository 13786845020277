import {Component, Inject, OnInit} from '@angular/core';
import {environment}               from "../../environments/environment";
import {ActivatedRoute, Router}    from "@angular/router";
import {DOCUMENT}                  from "@angular/common";
import {UpdateContactService}      from "../shared/services/update-contact.service";
import {AuthService}               from "../shared/services/auth.service";

@Component({
             selector   : 'app-update-information',
             templateUrl: './update-information.component.html',
             styleUrl   : './update-information.component.scss'
           })
export class UpdateInformationComponent implements OnInit {
  public cenet_id: string | null  = null;
  public userArr                  = [];
  public emailRequire: boolean    = false;
  public contactRequire: boolean  = false;
  public emailValidation: boolean = false;
  formerEmpData: any;
  public cellPhone: string        = '';
  public mailid: string | null    = null;
  public homePhone: string        = '';
  public confirmPage: boolean     = false;
  public loadSpinner: boolean     = true;
  public countryCode: any         = '+1';
  contactLength: boolean          = false;
  altercontactLength: any;
  message: any;
  responseMSG: string | null      = null;
  errorCounter: number            = 0;

  constructor(private router: Router, protected route: ActivatedRoute, @Inject(DOCUMENT) private document: any,
              private authService: AuthService, private updateContactService: UpdateContactService) { }

  ngOnInit() {
    this.loadSpinner = true;
    this.cenet_id    = this.route.snapshot.paramMap.get('cenet_id');
    this.getEmpData();
    this.cellPhone = '';
    this.mailid    = '';
    this.homePhone = '';
    let bases      = this.document.getElementsByTagName('base');
    console.log(bases[0])
    console.log(bases[0].baseURI)
    let url = window.location.href
    if (bases.length > 0 && (url.includes('www.e-access.att.com') || url.includes('webtest.stage.att.com'))) {
      console.log("in if block")
      console.log(bases[0])
      console.log(bases[0].baseURI);
      bases[0].setAttribute('href', environment.IV_JCT + '/hraccess/#/updateInfo');
    }

  }

  // ngAfterViewInit() {
  //   console.log(this.cookieService.get('IV_JCT'))
  //   let bases = this.document.getElementsByTagName('base');
  //   console.log(bases);
  //   if (bases.length > 0) {
  //     bases[0].setAttribute('href', this.cookieService.get('IV_JCT') + '/hraccess/#/updateInfo');
  //   }
  // }


  validateEmail(event: any) {
    const emailStr = event.target.value;
    const emailPat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (emailStr === '') {
      return true;
    }
    const matchArray = emailStr.match(emailPat);
    if (matchArray == null) {
      this.emailValidation = true;
      return false;
    } else {
      this.emailValidation = false;
      return true;
    }
  }

  getEmpData() {
    this.loadSpinner = true
    this.updateContactService.getEmpData().subscribe(
      {
        next : (data: any) => {
          if (data) {
            this.loadSpinner   = false
            this.formerEmpData = data;
            this.mailid        = data.userData.personalEmail;
            this.homePhone     = data.userData.homePhone == 0 ? '' : data.userData.homePhone;
            this.cellPhone     = data.userData.cellNumber == 0 ? '' : data.userData.cellNumber;
          }
        },
        error: (error: any) => {
          this.loadSpinner = false;
          console.error(error);
        }
      }
    );
  }

  updateEmpData() {
    this.mailid === '' ? this.emailRequire = true : this.emailRequire = false;
    this.cellPhone === '' ? this.contactRequire = true : this.contactRequire = false;
    this.cellPhone?.length >= 1 && this.cellPhone.length < 10 ? this.contactLength = true : this.contactLength = false;
    this.homePhone.length >= 1 && this.homePhone.length < 10 ? this.altercontactLength = true : this.altercontactLength
      = false;
    if (!this.contactLength && !this.altercontactLength && !this.emailRequire && !this.contactRequire) {
      this.loadSpinner = true
      let data         = {
        "cspid"       : this.formerEmpData.userData.cspId,
        "cenetId"     : this.formerEmpData.cenetId,
        "cellNo"      : this.cellPhone,
        "emailAddress": this.mailid,
        "homePhone"   : this.homePhone,
        "countryCode" : this.countryCode
      }

      this.updateContactService.updateContact(data).subscribe(
        {
          next : (data: any) => {
            if (data) {
              if (data === 'SUCCESS') {
                this.confirmPage = true;
                this.loadSpinner = false
                this.responseMSG = 'Success';
                this.message     = 'Successfully Updated'
              } else {
                this.loadSpinner  = false
                this.errorCounter = this.errorCounter + 1
                this.responseMSG  = 'Error';
                this.confirmPage  = true;
                this.message      = data
              }
              this.loadSpinner = true;
            }
          },
          error: (error: any) => {
            this.loadSpinner = false;
            console.error(error);
          }
        }
      );
    }

  }

  validationCheck(event: any) {
    if (event === 'email') {
      this.mailid === '' ? this.emailRequire = true : this.emailRequire = false;
    } else if (event === 'contact') {
      this.cellPhone === '' ? this.contactRequire = true : this.contactRequire = false;
      this.cellPhone.length >= 1 && this.cellPhone.length < 10 ? this.contactLength = true : this.contactLength = false;
    } else if (event === 'altercontact') {
      this.homePhone.length >= 1 && this.homePhone.length < 10 ? this.altercontactLength = true
                                                               : this.altercontactLength = false;
    }
  }

  navigateToFormerEmp() {
    window.open(environment.former_emp, "_self")
  }
}
