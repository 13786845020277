<div *ngIf="loadSpinner" class="d-flex justify-content-center h-80 align-items-center">
  <mat-progress-spinner class="loader-area" mode="indeterminate"></mat-progress-spinner>
</div>
<div *ngIf="!loadSpinner" class="register d-flex justify-content-center">
  <div *ngIf="!confirmPage" class="signup-form">
    <!-- <form> -->
    <h2>Contact Information</h2>
    <p class="hint-text">Please update the Email, Personal Mobile No.and Alternate Phone No.</p>
    <div class="form-group row">
      <label class="col-md-3">User ID:</label>
      <div class="col-md-2"></div>
      <div class="col-md-7">
        {{formerEmpData.userData.cspId}}
      </div>
    </div>
    <div class="form-group row">
      <label class="col-md-3">First Name:</label>
      <div class="col-md-2"></div>
      <div class="col-md-7">
        {{formerEmpData.userData.firstName}}
      </div>
    </div>
    <div class="form-group row">
      <label class="col-md-3">Last Name:</label>
      <div class="col-md-2"></div>
      <div class="col-md-7">
        {{formerEmpData.userData.lastName}}
      </div>
    </div>
    <div class="form-group row required">
      <label class="col-md-3">Email:</label>
      <div class="col-md-2"></div>
      <div class="col-md-7">
        <input (blur)="validationCheck('email');validateEmail($event)" [(ngModel)]="mailid" class="form-control"
               type="email">
        <span *ngIf="emailRequire" style="color: #dc3545;">
                    Email field is mandatory.
                </span>
        <span *ngIf="emailValidation" style="color: #dc3545;">
                    Please enter the valid Email.
                </span>
      </div>
    </div>
    <div class="form-group row required">
      <label class="col-md-3">Personal Mobile No:</label>
      <div class="col-md-2">
        <input [(ngModel)]="countryCode" class="form-control" disabled="true" maxlength="2" name="countryCode"
               type="tel" value="+1">
      </div>
      <div class="col-md-7">
        <input (blur)="validationCheck('contact')" [(ngModel)]="cellPhone" class="form-control" maxlength="10"
               placeholder="Personal Mobile No" type="tel">
        <span *ngIf="contactRequire" style="color: #dc3545;">
                    Personal Mobile No field is mandatory.
                </span>
        <span *ngIf="contactLength" style="color: #dc3545;">
                    Maximum & Minimum length is 10.
                </span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-md-3">Alternate Phone No:</label>
      <div class="col-md-2">
        <input [(ngModel)]="countryCode" class="form-control" disabled="true" maxlength="2" name="countryCode"
               type="tel" value="+1">
      </div>
      <div class="col-md-7">
        <input (blur)="validationCheck('altercontact')" [(ngModel)]="homePhone" class="form-control" maxlength="10"
               placeholder="Alternate Phone No" type="tel">
        <span *ngIf="altercontactLength" style="color: #dc3545;">
                    Maximum & Minimum length is 10.
                </span>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-4" style="margin-left: 25%">
        <button (click)=updateEmpData()
                class="button-bottom btn btn-outline-success" type="submit">Submit
        </button>
      </div>
      <div class="col-md-3">
        <button (click)="navigateToFormerEmp()" class="btn btn-outline-primary sm-4" type="button">
          Cancel
        </button>
      </div>
      <!-- </form> -->
    </div>
  </div>

  <div *ngIf="confirmPage" class="confirmPage mat-elevation-z4">
    <h3 style="font-family: 'Roboto', sans-serif;">{{responseMSG}}</h3>
    <p style="font-family: 'Roboto', sans-serif;">{{message}}</p>
    <button (click)="navigateToFormerEmp()" class="btn btn-outline-primary sm-4" type="button">
      Ok
    </button>
  </div>
</div>
