import {Injectable}                                           from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable}                                           from 'rxjs';
import {environment}                                          from "../../environments/environment";

@Injectable()
export class AppInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (environment.env_name === 'local') {
      const authReq = request.clone(
        {
          setHeaders: {
            'iv-user'    : 'feao0270',
            'csp-cenetid': '155889917'
          }
        }
      );
      return next.handle(authReq);
    }
    return next.handle(request);
  }
}

// 'iv-user'    : 'na9994',
// 'csp-cenetid': '158718666'


// 'iv-user'    : 'ps1839',
// 'csp-cenetid': '11484911'

// 'iv-user'    : 'feao0270',
// 'csp-cenetid': '155889917'
