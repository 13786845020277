<div *ngIf="loadSpinner" class="spinner">
  <mat-spinner></mat-spinner>
</div>
<div class="w-100 text-center ">

  <div class="row m-5">
    <div class="col border-bottom"></div>
    <div class="col fs-2">Get Username</div>
    <div class="col border-bottom"></div>
  </div>

  <div class="row m-5">
    <div class="col"></div>
    <div class="col">
      New here? Type in your ATTUID you previously used while employees with AT&T. Next enter the captcha code and
      hit submit:
    </div>
    <div class="col"></div>
  </div>

  <div class="form-group row required m-5">
    <div class="col-md-3"></div>
    <label class="col-md-2" for="attuid">ATTUID:
      <img
        alt="help question mark icon"
        class="helpimageCss"
        matTooltip="Please enter your name exactly as it appears in the AT&T Payroll system (PayStubs,W2).Spouses/Dependents please enter your name exactly as it appears in the Benefits system "
        matTooltipClass="my-custom-tooltip"
        src="assets/help.png" />
    </label>
    <div class="col-md-3">
      <input [(ngModel)]="attuid" class="form-control" id="attuid" name="firstname" placeholder="ATTUID">
      <span *ngIf="attuidRequire" class="required-field">ATTUID is mandatory.</span>
    </div>
  </div>

  <div class="form-group row required m-5">
    <div class="col-md-3"></div>

    <label class="col-md-2" for="createCaptcha">Captcha Code:
      <img alt="Captcha Code" class="helpimageCss" matTooltip="Please enter the code exactly as it is displayed. If you would like a new code, click the
                                refresh button."
           matTooltipClass="my-custom-tooltip" src="assets/help.png" />
    </label>
    <div class="col-md-3">
      <div class="bg-bisque" id="createCaptcha"></div>
    </div>
    <div class="col-md-3 d-flex align-content-start">
      <button (click)="createCaptcha()" class="btn btn-outline-info" type="button">
        <mat-icon>autorenew</mat-icon>
      </button>
    </div>
  </div>

  <div class="form-group row required m-5">
    <div class="col-md-3"></div>
    <label class="col-md-2" for="captchaValue">Enter Captcha:</label>
    <div class="col-md-3">
      <input (blur)="validateCaptcha()" [(ngModel)]="captchaValue" class="form-control" id="captchaValue"
             name="captchaValue" placeholder="Enter above code" type="text">
      <span *ngIf="captchaRequire" class="row required-field">
                Captcha code is missing or invalid, Please enter above text.
            </span>
    </div>
  </div>


  <div class="m-5">Click here for <a (click)="openHelpDialog()" class="link">help</a>.</div>

  <div class="form-group row m-5">
    <div class="col-md-3"></div>
    <div class="col-md-3">
      <button (click)="submit()" class="button-bottom btn btn-outline-success"
              type="submit">
        Submit
      </button>
    </div>
    <div class="col-md-3">
      <button (click)="cancel()" class="button-bottom btn btn-outline-danger"
              type="submit">
        Cancel
      </button>
    </div>
  </div>
</div>
