<div *ngIf="loadSpinner" class="spinner">
  <mat-spinner></mat-spinner>
</div>
<div *ngIf="!confirmPage">
  <div class="register">
    <div class="signup-form d-flex justify-content-center">
      <form>
        <div *ngIf="!existingFlag">
          <h2>{{pageTitle}}</h2>
          <p class="hint-text">Please fill this form to create an account!</p>
        </div>
        <div *ngIf="existingFlag">
          <h2>Reset Password</h2>
          <p class="hint-text">Please fill out this form to reset password or get User Name reminder</p>
        </div>
        <!--                disableAttuid:{{disableAttuid}} | disableName:{{disableName}}-->
        <div class="form-group row required">
          <label class="col-3" for="attuid">ATTUID:
            <img
              alt="c"
              class="helpimageCss" height="512"
              matTooltip="Please enter your attuid or First and Last Name"
              matTooltipClass="my-custom-tooltip" ngSrc="assets/help.png" width="512" />
          </label>
          <div class="col-9">
            <input (blur)="validationCheck('attuid')" (keyup)="textCounter('attuid')" [(ngModel)]="attuid"
                   [disabled]="readonlyAttuid" class="form-control"
                   id="attuid" name="attuid"
                   placeholder="ATTUID" type="text">
            <span *ngIf="attuidRequire" class="required-field">
                            ATTUID or (First Name and Last Name) field is mandatory.
                        </span>
          </div>
        </div>
        <div class="form-group row text-center">
          <div class="col">OR</div>

        </div>
        <div class="form-group row required">
          <label class="col-3" for="firstname">First Name:
            <img
              alt="help question mark icon"
              class="helpimageCss"
              height="512"
              matTooltip="Please enter your name exactly as it appears in the AT&T Payroll system (PayStubs,W2).Spouses/Dependents please enter your name exactly as it appears in the Benefits system "
              matTooltipClass="my-custom-tooltip" ngSrc="assets/help.png" width="512" />
          </label>
          <div class="col-9">
            <input (blur)="validationCheck('firstname')" (keyup)="textCounter('firstname')" [(ngModel)]="firstname"
                   [disabled]="readonlyName" class="form-control" id="firstname" name="firstname"
                   placeholder="First Name" type="text">
            <span *ngIf="fnRequire" class="required-field">
                            First Name field is mandatory.
                        </span>
          </div>
        </div>
        <div class="form-group row required">
          <label class="col-3" for="lastname">Last Name:
            <img
              alt="help question mark icon"
              class="helpimageCss"
              height="512"
              matTooltip="Please enter your name exactly as it appears in the AT&T Payroll system (PayStubs,W2).Spouses/Dependents please enter your name exactly as it appears in the Benefits system "
              matTooltipClass="my-custom-tooltip" ngSrc="assets/help.png" width="512" />
          </label>
          <div class="col-9">
            <input (blur)="validationCheck('lastname')" (keyup)="textCounter('lastname')" [(ngModel)]="lastname"
                   [disabled]="readonlyName" class="form-control" id="lastname" name="lastname" placeholder="Last Name"
                   type="text">
            <span *ngIf="lnRequire" class="required-field">
                            Last Name field is mandatory.
                        </span>
          </div>
        </div>
        <hr>
        <div [ngClass]="registrationFlow?'required':''" class="form-group row">
          <label class="col-3" for="email">Personal Email:
            <img [matTooltip]="emailToolTip"
                 alt="help question mark icon" class="helpimageCss" height="512"
                 matTooltipClass="my-custom-tooltip" ngSrc="assets/help.png" width="512" />
          </label>
          <div class="col-9">
            <input (blur)="validationCheck('email');validateEmail($event)" [(ngModel)]="email" class="form-control"
                   id="email" name="email"
                   placeholder="Email" type="email">
            <span *ngIf="emailRequire" class="required-field">
                            Email field is mandatory.
                        </span>
            <span *ngIf="emailValidation" class="required-field">
                            Please enter the valid Email.
                        </span>
          </div>
        </div>
        <div class="form-group row required">
          <label class="col-3" for="contact">Personal Mobile No:
            <img alt="help question mark icon" class="helpimageCss" height="512"
                 matTooltip="By adding a personal mobile phone number, you agree AT&T may text registration and
                                password reset instructions. If your
                                personal information changes, please update immediately. Standard text messaging rates
                                may apply" matTooltipClass="my-custom-tooltip" ngSrc="assets/help.png" width="512" />
          </label>
          <div class="col-3">
            <input [(ngModel)]="countryCode" class="form-control text-center" disabled="true" maxlength="2"
                   name="countryCode" type="tel" value="+1">
          </div>
          <div class="col-6">
            <input (blur)="validationCheck('contact')" [(ngModel)]="contact" class="form-control" id="contact"
                   maxlength="10"
                   minlength="10" name="contact" placeholder="Personal Mobile No"
                   type="tel">
            <span *ngIf="contactRequire" class="required-field">
                            Personal Mobile No field is mandatory.
                        </span>
            <span *ngIf="contactLength" class="required-field">
                            Maximum & Minimum length is 10.
                        </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-3" for="alternatecontact">Alternate Phone No:
          </label>
          <div class="col-3">
            <input [(ngModel)]="countryCode" class="form-control text-center" disabled="true" maxlength="2"
                   name="countryCode" type="tel" value="+1">
          </div>
          <div class="col-6">
            <input (blur)="validationCheck('altercontact')" [(ngModel)]="altercontact" class="form-control"
                   id="alternatecontact"
                   maxlength="10" name="altercontact" placeholder="Alternate Phone No"
                   type="tel">
            <span *ngIf="altercontactLength" class="required-field">
                            Maximum & Minimum length is 10.
                        </span>
          </div>
        </div>
        <div class="form-group row required">
          <label class="col-3" for="dob">Date of Birth:
            <img alt="help question mark icon" class="helpimageCss" height="512"
                 matTooltip="Your date of birth is required to validate and authenticate your registration for the
                                Former Employee Site." matTooltipClass="my-custom-tooltip" ngSrc="assets/help.png"
                 width="512" />
          </label>
          <div class="col-9" id="dob">
            <input (blur)="validationCheck('dobMM')" (keyup)="textCounter('dobMM')" [formControl]="twoDigitsMM"
                   [maxLength]="2" class="col-2 text-center" id="dobMM" maxlength="2" name="dobMM" placeholder="MM"
                   type="number">
            <span class="inputConnector"> - </span>
            <input (blur)="validationCheck('dobDD')" (keyup)="textCounter('dobDD')" [formControl]="twoDigitsDD"
                   [maxLength]="2" class="col-2 text-center" id="dobDD" maxlength="2" name="dobDD" placeholder="DD"
                   type="number">
            <span class="inputConnector"> - </span>
            <input (blur)="validationCheck('dobYYYY')" [formControl]="fourDigitsYYYY" [maxLength]="4"
                   class="col-3 text-center" id="dobYYYY" maxlength="4" name="dobYYYY" placeholder="YYYY" type="number">
            <span *ngIf="dobRequire" class="required-field">
                            Date of Birth field is mandatory. (Format: MM-DD-YYYY)
                        </span>
          </div>
        </div>
        <div class="form-group row required">
          <label class="col-3" for="ssn">SSN:
            <img alt="help question mark icon" class="helpimageCss" height="512"
                 matTooltip="Your SSN is required to validate and authenticate your registration for the Former
                                Employee Site" matTooltipClass="my-custom-tooltip" ngSrc="assets/help.png"
                 width="512" />
          </label>
          <div *ngIf="showSSNCondition" class="help_modal">
            <section>
              <p>Your SSN is required to validate and authenticate your registration for the Former
                 Employee Site</p>
            </section>
          </div>
          <div class="col-9">
            <input (blur)="validationCheck('ssn')" (keyup)="textCounter('ssn1')" [(ngModel)]="firstssn"
                   class="col-3 text-center"
                   id="ssn" maxlength="3" name="firstssn"
                   type="password">

            <span class="inputConnector"> - </span>
            <input (blur)="validationCheck('ssn')" (keyup)="textCounter('ssn2')" [(ngModel)]="midssn"
                   class="col-2 text-center" maxlength="2"
                   name="midssn " type="password">

            <span class="inputConnector"> - </span>
            <input (blur)="validationCheck('ssn')" [(ngModel)]="lastssn" class="col-3 text-center" maxlength="4"
                   name="lastssn" type="password">
            <span *ngIf="ssnRequire || firstSsnLength || midSsnLength || lastSsnLength"
                  class="row required-field">
                            SSN is required
                        </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-3" for="createCaptcha">Captcha Code:
            <img alt="Captcha Code" class="helpimageCss" height="512"
                 matTooltip="Please enter the code exactly as it is displayed. If you would like a new code, click the
                                refresh button." matTooltipClass="my-custom-tooltip" ngSrc="assets/help.png"
                 width="512" />
          </label>
          <div class="col-4 bisque-bg" id="captcha"></div>
          <div class="col-4 d-flex align-content-start ">
            <button (click)="createCaptcha()" class="btn btn-outline-info" id="createCaptcha" type="button">
              <mat-icon>autorenew</mat-icon>
            </button>
          </div>
        </div>
        <div class="form-group row required">
          <label class="col-3" for="captchaValue">Enter Captcha:</label>
          <div class="col-9">
            <input (blur)="validateCaptcha('captchaValue')" [(ngModel)]="captchaValue" class="form-control"
                   id="captchaValue"
                   name="captchaValue" placeholder="Enter above code" type="text">
            <span *ngIf="captchaRequire" class="row required-field">
                            Captcha code is missing or invalid, Please enter above text.
                        </span>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-6 d-flex justify-content-end">
            <button (click)="submit()" class="button-bottom btn btn-outline-success"
                    type="submit">Submit
            </button>
          </div>
          <div class="col-6">
            <button (click)="cancel()" class="button-bottom btn btn-outline-danger"
                    type="submit">Cancel
            </button>
          </div>
        </div>
        <div class="text-center">
          Already have an account? <a href="#">Sign in</a>
        </div>
      </form>
    </div>
  </div>
</div>

<div *ngIf="confirmPage" class="confirmPage mat-elevation-z4">
  <h3 style="font-family: 'Roboto', sans-serif;">{{responseMSG}}</h3>
  <p style="padding: 3%;font-family: 'Roboto', sans-serif;">{{message}}</p>
  <p *ngIf="existingUser" style="padding: 3%;font-family: 'Roboto', sans-serif;">You are already registered in
                                                                                 system. Your
                                                                                 UserName is <b>{{userId}}</b>.If you
                                                                                 forgot your password, please click <a
      [routerLink]="['/forgotpwd']">forgot password</a></p>
  <button (click)="navigateToPage()">Ok</button>
  <div *ngIf="errorCounter >= 3" class="service_desk">
    <p>
      Are you experiencing issues? Call HR OneStop at 888-722-1STP (888-722-1787) to speak directly to a HR
      OneStop Customer Care Manager. Speak “Former Employee Site”.
    </p>
    <p>
      Hours of Operation: 8:00 am – 5:00 pm CST
    </p>
  </div>
</div>
